import React, { useEffect, useState } from "react";
import DataQuery from "../../data/DataQuery";
import IRepository from "../../data/IRepository";
import { useTranslation } from "react-i18next";
import { Modal, Col, Row, Form, Button, Badge, Accordion, Card } from "react-bootstrap";
import { BuyTrancheDTO } from "../buyTranche/dto/BuyTrancheDTO";
import { PurchasedDTO } from "../purchasedTranches/dto/PurchasedDTO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemWithPrices from "../itemWithPrices/ItemWithPrices";
import { faPlus, faMinus, faShoppingCart, faSpinner, faBug } from "@fortawesome/free-solid-svg-icons";
import Detail from "../customer/sections/Detail";

interface IProps {
    trancheToBuy?: BuyTrancheDTO;
}

const Tranche: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["modules\\dashboard"]);
    const [buyResult, setbuyResult] = useState<PurchasedDTO | undefined>();

    const getDeal = async (): Promise<void> => {
        let params = props.trancheToBuy;
        const axios = require('axios');
        let url = process.env["REACT_APP_FLEXI_API_URL"];

        let data = await axios.post(url + 'BuyTranche', params);

        setbuyResult(data.data);
    };

    useEffect(() => {
        getDeal();
    }, []);

    const renderModalBody = (pResult?: PurchasedDTO) => {
           if (!pResult)
                return (
                    <div className="loadingModal">
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                        <p>{t("loadingBuy")}</p>
                    </div>
                );

        const status = (pResult.data != null) ? ((pResult.data.resultList[0].purchaseEnvStatus == "OK") ? true : false) : false;

        if (status) {            
                return (
                    <React.Fragment>
                        <Col lg="12">
                            <Row className="text-center">
                                <h1>{t("uspech")}</h1>
                            </Row>

                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("vzorec")}:
                                </dt>
                                <dd className="col-lg-6">
                                    {pResult.data.formulaList[0].formulaName}
                                </dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("shopFromTo")}:
                                </dt>
                                <dd className="col-lg-6">
                                    {pResult.data.formulaList[0].periodList[0].periodCode} ({pResult.data.formulaList[0].periodList[0].kindDerivationPrice})
                            </dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("baseload")}:
                                </dt>
                                <dd className="col-lg-6">
                                    {pResult.data.formulaList[0].periodList[0].priceBaseLoad.toFixed(3).replace('.', ',')} [EUR/MWh]
                                </dd>
                            </dl>
                            {/*<dl className="row">
                                <dt className="col-lg-6 text-right">
                                    PeakLoad:
                                </dt>
                                <dd className="col-lg-6">
                                    {pResult.data.formulaList[0].periodList[0].pricePeakLoad} [EUR/MWh]
                                </dd>
                            </dl>*/}
                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("datumceny")}:
                                </dt>
                                <dd className="col-lg-6">
                                    {new Date(pResult.data.formulaList[0].periodList[0].dateLoadedPrice).toLocaleDateString()} {new Date(pResult.data.formulaList[0].periodList[0].dateLoadedPrice).getHours() + ":" + (new Date(pResult.data.formulaList[0].periodList[0].dateLoadedPrice).getMinutes() < 10 ? '0' : '') + new Date(pResult.data.formulaList[0].periodList[0].dateLoadedPrice).getMinutes() + ":" + (new Date(pResult.data.formulaList[0].periodList[0].dateLoadedPrice).getSeconds() < 10 ? '0' : '') + new Date(pResult.data.formulaList[0].periodList[0].dateLoadedPrice).getSeconds()}
                                </dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("kurz")}:
                                </dt>
                                <dd className="col-lg-6">
                                    {pResult.data.formulaList[0].periodList[0].exchangeRate.toFixed(2).replace('.', ',')} [CZK/EUR]
                                </dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("calcena")}:
                                </dt>
                                <dd className="col-lg-6">
                                    {pResult.data.formulaList[0].periodList[0].calculatedPrice.toFixed(2).replace('.', ',')} [{pResult.data.formulaList[0].formulaName.split(":")[0]}]
                                </dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("zbyva")}:
                                </dt>
                                <dd className="col-lg-6">
                                    {pResult.data.formulaList[0].periodList[0].amountRemaining.toFixed(3).replace('.', ',')} {t("jednotka")}
                                </dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("povolene")}:
                                </dt>
                                <dd className="col-lg-6">
                                    {pResult.data.formulaList[0].periodList[0].overAmountRemAllowed ? "Ano" : "Ne"}
                                </dd>
                            </dl>

                            <dl className="row">
                                <dt className="col-lg-6 text-right">
                                    {t("nakoupeno")}:
                                </dt>
                                <dd className="col-lg-6">
                                    <strong className="green">{pResult.data.formulaList[0].periodList[0].amount.toFixed(3).replace('.', ',')} {t("jednotka")}</strong>
                                </dd>
                            </dl>
                        </Col>
                    </React.Fragment>
                );
            
        } else {
            if (pResult.data != null) {
                return (
                    <div className="errorModal">
                        <FontAwesomeIcon icon={faBug} />
                        <h1>{t("neuspech")}</h1>
                        <p>{t("error")}
                            <br />
                            <small>
                                {pResult.data.resultList[0].description}
                            </small>
                            <br />
                            <br />
                            {t("errordesc")}
                        </p>
                    </div>
                );
            } else {
                return (
                    <div className="errorModal">
                        <FontAwesomeIcon icon={faBug} />
                        <h1>{t("neuspech")}</h1>
                        <p>{t("error")}
                            <br />
                            <small>
                                {pResult.messageError}
                            </small>
                            <br />
                            <br />
                            {t("errordesc")}
                        </p>
                    </div>
                );
            }            
        }
    };

    return (
        <>
            {renderModalBody(buyResult)}
        </>
    );
};

export default Tranche;