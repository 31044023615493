import React, { useState } from "react";
import { useTranslation } from "react-i18next";


import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TopBar from "./topbar/components/TopBar";
import TopBarLogo from "./topbar/components/TopBarLogo";
import ProfileDialog from "../../sidebar/ProfileDialog";

import NavBar from "./NavBar";
import SideBar from "./Sidebar";
import { DeviceTypeEnum } from "../enums/DeviceTypeEnum";

import * as Icon from "react-bootstrap-icons";
import SidebarItem from "../models/SidebarItem";

import Alert from "../alerts/components/Alert";
import AlertDTO from "../alerts/dto/AlertDTO";
import AlertTypeEnum from "../enums/AlertTypeEnum";

import styles from "./layout.module.scss";

interface IProps { }

const Layout: React.FC<IProps> = (props) => {
    const [profileDialogShow, setProfileDialogShow] = useState(false);

    const toggleModal = (e: any, showBar: boolean) => {
        setProfileDialogShow(showBar);
    }

    return (
        <>
            <TopBarLogo type={DeviceTypeEnum.MOBILE} />
            <TopBar onSidebar={toggleModal}></TopBar>
            <ProfileDialog
                show={profileDialogShow}
                setShow={setProfileDialogShow}
            />

            <Container fluid className={`${styles.pageContainer} ${process.env.REACT_APP_CLIENT_NAME}`}>
                <Col lg="12">
                    <Row>
                        {props.children}
                    </Row>
                </Col>
            </Container>
        </>
    );
};

export default Layout;
