import React from "react";
import { processSilentRenew } from "redux-oidc";

interface IProps {}

const SilentRenewPage: React.FC<IProps> = (props) => {
  return (
    <>
      {processSilentRenew()}
      <div>SilentRenewComponent</div>
    </>
  );
};

export default SilentRenewPage;
