import React, { ReactElement } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import TopBarLogo from "./TopBarLogo";
import MessagesMenu from "./MessagesMenu";
import MessageDTO from "../dto/MessageDTO";
import { DeviceTypeEnum } from "../../../enums/DeviceTypeEnum";
import NotificationsMenu from "./NotificationsMenu";
import AlertDTO from "../../../alerts/dto/AlertDTO";
import AlertTypeEnum from "../../../enums/AlertTypeEnum";

import styles from "./topbar.module.scss";
import UserManager from "../../../../../infrastructure/oidc/UserManager";
import { useDispatch, useSelector } from "react-redux";
import { USER_SIGNED_OUT, UserState } from "redux-oidc";
import IAppState from "../../../../../infrastructure/store/IAppState";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
    onSidebar: (e: any, showBar: boolean) => void;
}

const TopBar: React.FC<IProps> = (props) => {

    const dispatch = useDispatch();
    let userState = useSelector<IAppState, UserState>((store) => store.oidc);

    // END TEMPORARY

    return (
        <nav className={`navbar navbar-expand ${styles.topbar} ${process.env.REACT_APP_CLIENT_NAME}`}>
            <TopBarLogo type={DeviceTypeEnum.DESKTOP} />
            <div className="d-flex ml-auto">
                <div className={styles.topbarActionItem}>
                    <button className={`btn shadow-none ${styles.topbarActionLink}`} onClick={(e: any) => props.onSidebar(e, true)}>
                        <span className={`mr-sm-2 ${styles.topbarProfileAvatar}`}>
                            <FontAwesomeIcon icon="user" />
                        </span>
                        <span className={`d-none d-sm-inline-block text-truncate ${styles.topbarProfileText}`}>{userState.user?.profile.email}</span>
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default TopBar;