import { Reducer } from "redux";
import { MainActionTypes, MainAction } from "./mainActionTypes";
import IMainState from "./IMainState";
import TableStateModel from "../../../features/shared/models/TableStateModel";

const initialState: IMainState = {
  tablesState: new Array<TableStateModel>(),
};

const MainReducer: Reducer<IMainState, MainAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MainActionTypes.SET_TABLE_STATE: {
      const tableState = state.tablesState.filter(
        (x) => x.code !== action.data.code
      );

      tableState.push(action.data);

      return {
        ...state,
        tablesState: tableState,
      };
    }
    default: {
      return state;
    }
  }
};

export default MainReducer;
