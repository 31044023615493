import React, { useEffect, useState } from "react";
import DataQuery from "../../data/DataQuery";
import IRepository from "../../data/IRepository";
import { useTranslation } from "react-i18next";
import { Modal, Col, Row, Form, Button, Badge } from "react-bootstrap";
import { PeriodList } from "../formula/dto/FormulaListDTO";
import { CenaTranse } from "../formula/dto/FormulaListDTO";
import { Percentage } from "../percentage/dto/PercentageDTO";
import { PercentageUnformatted } from "../percentage/dto/PercentageDTO";
import { UnformattedInput } from "../percentage/dto/UnformattedInputDTO";
import { Timer } from "../percentage/dto/UnformattedInputDTO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faChartLine, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    detailInfo: PeriodList;
    formulaName: string;
    formulaDisplayName: string;
    obchodniDen: boolean;
    onSelectItem: (detailInfo: PeriodList, formulaName: string, formulaDisplayName: string, value?: number) => void;
    komodita: string;
    kodBanky: string;
}

const ItemWithPrices: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["modules\\dashboard"]);
    const [inputValue, setInputValue] = useState<number | undefined>();
    const [inputValueUnformatted, setInputValueUnformatted] = useState<UnformattedInput>({ value: 0 });
    const [percentValue, setPercentValue] = useState<Percentage>({ percents: 0 });
    const [percentValueUnformatted, setPercentValueUnformatted] = useState<PercentageUnformatted>({ percents: 0 });
    const [canBuy, setCanBuy] = useState(false);
    const [copy, setCopy] = useState("");
    const [cenaTranse, setcenaTranse] = useState<CenaTranse>({ cena: 0 });

    const priceName = props.formulaName.split(":")[0];

    function startBuyTranche() {
        props.onSelectItem(props.detailInfo, props.formulaName, props.formulaDisplayName, inputValue);
    }

    if (props.detailInfo.calculatedPrice < 2) {
        setCanBuy(false);
    }

    if (!props.obchodniDen) {
        setCanBuy(false);
    }

    var odkazNaBanku = "";


    if (props.komodita == "EE") { //kody pro EE
        if (props.kodBanky == "PXE") {
            if (props.detailInfo.kindDerivationPrice == "Settlement") { //EE-PXE-SETT
                odkazNaBanku = "https://www.pxe.cz/Kurzovni-Listek/Oficialni-KL/";
            } else {//EE-PXE-BESTASK
                odkazNaBanku = "https://www.pxe.cz/On-Line/Futures/";
            }
        }
    } else {//Kody pro ZP
        if (props.kodBanky == "PXE") {
            if (props.detailInfo.kindDerivationPrice == "Settlement") { //ZP-PXE-SETT
                odkazNaBanku = "https://www.pxe.cz/Kurzovni-Listek/Oficialni-KL/";
            } else {//ZP-PXE-BESTASK
                odkazNaBanku = "http://pxe.cz/On-Line/Gas/";
            }
        } else {
            odkazNaBanku = "https://www.powernext.com/futures-market-data";
        }
    }

    function handleChange(e: any) { //nastaven� mno�stv� k n�kupu a kontrola, zda lze kupovat      

        let value: number = e.target.value;
        let total = (props.detailInfo.amountPlanning / 100);
        let newAmmountPercentage = value / total;

        if (value <= 0) {
            setInputValue(undefined);
            value = 0;
            newAmmountPercentage = 0;
        } else if (!props.detailInfo.overAmountRemAllowed) {
            if (value > props.detailInfo.amountRemaining) {
                setInputValue(props.detailInfo.amountRemaining);
                value = props.detailInfo.amountRemaining;
                newAmmountPercentage = value / total;
            } else {
                setInputValue(value);
                newAmmountPercentage = value / total;
            }
        } else {
            setInputValue(value);
            newAmmountPercentage = value / total;
        }


        setPercentValue({ percents: newAmmountPercentage });
        setPercentValueUnformatted({ percents: newAmmountPercentage });

        setCopy("notOk");

        var transe = value * props.detailInfo.calculatedPrice;
        setcenaTranse({ cena: transe });

        if (value > 0) {
            setCanBuy(true);
        } else {
            setCanBuy(false);
        }

        if (props.detailInfo.calculatedPrice < 2) {
            setCanBuy(false);
        }
    }

    function numberWithSpaces(x: number) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(",");
    }

    function formatInputs() {
        var formatedInput = parseFloat(Number(inputValue).toFixed(3));
        setInputValue(formatedInput);

        var formatedPercents = parseFloat(Number(percentValue.percents).toFixed(3));
        setPercentValue({ percents: formatedPercents });
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => formatInputs(), 10);
        return () => clearTimeout(timeoutId);
    }, [inputValue]);

    function handleChangePercentage(e: any) {
        let value = e.target.value;
        let total = (props.detailInfo.amountPlanning / 100);

        let remainingAmmount = props.detailInfo.amountRemaining;

        if (!props.detailInfo.overAmountRemAllowed) {
            if (value > 100) {
                value = 100;
            }
        }

        if ((total * value) > remainingAmmount) {
            value = remainingAmmount / total;
        }

        let newAmmount = total * value;      

        setPercentValue({ percents: value });
        setInputValue(newAmmount);
        setInputValueUnformatted({ value: newAmmount });

        var transe = newAmmount * props.detailInfo.calculatedPrice;
        setcenaTranse({ cena: transe });

        if (newAmmount > 0) {
            setCanBuy(true);
        } else {
            setCanBuy(false);
        }

        if (props.detailInfo.calculatedPrice < 2) {
            setCanBuy(false);
        }

        setCopy("notOk");
    }


    useEffect(() => {
        const timeoutId = setTimeout(() => formatInputs(), 10);
        return () => clearTimeout(timeoutId);
    }, [percentValue.percents]);

    useEffect(() => {
        if (copy == "ok") {
            var numbertocopy = props.detailInfo.amountRemaining;

            setInputValue(numbertocopy);
            setInputValueUnformatted({ value: numbertocopy });

            var procenta = numbertocopy / (props.detailInfo.amountPlanning / 100)

            setPercentValue({ percents: procenta });
            setPercentValueUnformatted({ percents: procenta });

            var transe = numbertocopy * props.detailInfo.calculatedPrice;
            setcenaTranse({ cena: transe });

            if (props.detailInfo.amountRemaining > 0) {
                setCanBuy(true);
            } else {
                setCanBuy(false);
            }

            if (props.detailInfo.calculatedPrice < 2) {
                setCanBuy(false);
            }
        }

        if (props.detailInfo.calculatedPrice < 2) {
            setCanBuy(false);
        }
    }, [copy]);

    const alfabetagama = () => {
        var isIt = 0;

        if (props.detailInfo.alfa > 0) {
            isIt++;
        }

        if (props.detailInfo.beta > 0) {
            isIt++;
        }

        if (props.detailInfo.gama > 0) {
            isIt++;
        }

        let gridder = 0;

        if (isIt == 1)
            gridder = 12;

        if (isIt == 2)
            gridder = 6;

        if (isIt == 3)
            gridder = 4;

        return gridder;
    };


    return (
        <React.Fragment>
            <div className="balicek bg-light">
                <Row className="head">
                    <Col lg="10">
                        <h2>{props.detailInfo.periodCode} <Badge variant="primary">{props.detailInfo.kindDerivationPrice}</Badge></h2>
                    </Col>
                    {odkazNaBanku ?
                        <Col lg="2">
                            <a href={odkazNaBanku} target="_blank">
                                <FontAwesomeIcon icon={faChartLine} className="odkaznabanku" title={t("odkazNaBanku")} />
                            </a>
                        </Col> : <></>}
                </Row>

                {(alfabetagama() != 0) ? <Row className="body">
                    {(props.detailInfo.alfa > 0) ? <Col lg={alfabetagama()}>
                        <Form.Group>
                            <Form.Label><strong>{t("alfa")}</strong></Form.Label>
                            <p>{props.detailInfo.alfa.toFixed(4).replace('.', ',')}</p>
                        </Form.Group>
                    </Col> : <></>}
                    {(props.detailInfo.beta > 0) ? <Col lg={alfabetagama()}>
                        <Form.Group>
                            <Form.Label><strong>{t("beta")}</strong></Form.Label>
                            <p>{props.detailInfo.beta.toFixed(3).replace('.', ',')}</p>
                        </Form.Group>
                    </Col> : <></>}
                    {(props.detailInfo.gama > 0) ? <Col lg={alfabetagama()}>
                        <Form.Group>
                            <Form.Label><strong>{t("gama")}</strong></Form.Label>
                            <p>{props.detailInfo.gama.toFixed(3).replace('.', ',')}</p>
                        </Form.Group>
                    </Col> : <></>}
                </Row> : <></>}

                <Row className="body">
                    <Col lg={(props.komodita == "EE") ? 4 : 4}>
                        <Form.Group>
                            <Form.Label><strong>{t("baseload")}</strong></Form.Label>
                            <p>{(props.detailInfo.calculatedPrice < 2) ? "" : props.detailInfo.priceBaseLoad.toFixed(3).replace('.', ',') + " [EUR/MWh]"}</p>
                        </Form.Group>
                    </Col>

                    {/*(props.komodita == "EE") ?
                       <Col lg="4"> 
                            <Form.Group>
                                <Form.Label><strong>PeakLoad</strong></Form.Label>
                                <p>{(props.detailInfo.calculatedPrice < 2) ? "" : props.detailInfo.pricePeakLoad+ " [EUR/MWh]"}</p>
                            </Form.Group>
                        </Col> : <></>*/}
                    <Col lg={(props.komodita == "EE") ? 4 : 4}>
                        <Form.Group>
                            <Form.Label><strong>{t("cenatranse")}</strong></Form.Label>
                            <p>{numberWithSpaces(Number(cenaTranse.cena.toFixed(2)))} [{priceName}]</p>
                        </Form.Group>
                    </Col>

                    <Col lg={(props.komodita == "EE") ? 4 : 4}>
                        <Form.Group>
                            <Form.Label><strong>{t("kurz")}</strong></Form.Label>
                            <p>{props.detailInfo.exchangeRate.toFixed(2).replace('.', ',')} [CZK/EUR]</p>
                        </Form.Group>
                    </Col>

                    {/*<Col lg="4">
                        <Form.Group>
                            <Form.Label><strong>{t("povolene")}</strong></Form.Label>
                            <p>{props.detailInfo.overAmountRemAllowed ? "Ano" : "Ne"}</p>
                        </Form.Group>
                    </Col>*/}

                </Row>

                <Row className="body">
                    <Col lg="4">
                        <Form.Group>
                            <Form.Label><strong>{t("datumceny")}</strong></Form.Label>
                            <p>
                                {(new Date(props.detailInfo.dateLoadedPrice).toLocaleDateString() == "1. 1. 1") ? "-" : new Date(props.detailInfo.dateLoadedPrice).toLocaleDateString()} {(new Date(props.detailInfo.dateLoadedPrice).getHours() != 0) ? new Date(props.detailInfo.dateLoadedPrice).getHours()
                                    + ":" + (new Date(props.detailInfo.dateLoadedPrice).getMinutes() < 10 ? '0' : '')
                                    + new Date(props.detailInfo.dateLoadedPrice).getMinutes()
                                    + ":" + (new Date(props.detailInfo.dateLoadedPrice).getSeconds() < 10 ? '0' : '')
                                    + new Date(props.detailInfo.dateLoadedPrice).getSeconds() : props.detailInfo.kindDerivationPrice == "Settlement" && (new Date(props.detailInfo.dateLoadedPrice).toLocaleDateString() != "1. 1. 1") ? ", " + t("vecer") : ""}

                                {(new Date(props.detailInfo.dateLoadedPrice).toLocaleDateString() != "1. 1. 1" && props.detailInfo.kindDerivationPrice == "BestAsk") ? <span className="mgleft" title={t("bestaskmessage")}><FontAwesomeIcon icon={faInfoCircle} /></span> : <></>}

                            </p>
                        </Form.Group>
                    </Col>
                    <Col lg="4">
                        <Form.Group>
                            <Form.Label><strong>{t("calcena")}</strong></Form.Label>
                            <p>{props.obchodniDen ? numberWithSpaces(Number(props.detailInfo.calculatedPrice.toFixed(3))) + " [" + priceName + "]" : ""}</p>
                        </Form.Group>
                    </Col>
                    <Col lg="4">
                        <Form.Group>
                            <Form.Label><strong>{t("planzbyva")}</strong></Form.Label>
                            <p title={t("zbyvaonly") + " " + (props.detailInfo.amountRemaining / (props.detailInfo.amountPlanning / 100)).toFixed(3).replace('.', ',') + "%"}>{props.detailInfo.amountPlanning.toFixed(3).replace('.', ',')} {t("jednotka")} / {props.detailInfo.amountRemaining.toFixed(3).replace('.', ',')} {t("jednotka")}</p>
                        </Form.Group>
                    </Col>

                </Row>
                <Row className={props.obchodniDen ? "foot" : "foot disabled"}>
                    <Col lg="1" className="verCent text-center">
                        <FontAwesomeIcon onClick={() => setCopy("ok")} icon={faArrowAltCircleRight} className="bigButtonArrow" title={t("zbytektitle")} />
                    </Col>
                    <Col lg="4" className="verCent">
                        <input type="hidden" value={inputValueUnformatted.value} />
                        <Form.Control onInput={(e: any) => handleChange(e)} value={inputValue} type="number" min={0} max={props.detailInfo.overAmountRemAllowed ? "" : props.detailInfo.amountRemaining} placeholder={t("mnozstviknakoupeni")} />
                    </Col>
                    <Col lg="1" className="verCent text-center no-gutters">
                        [MWh]
                    </Col>
                    <Col lg="2" className="verCent">
                        <input type="hidden" value={percentValueUnformatted.percents} />
                        <Form.Control onInput={(e: any) => handleChangePercentage(e)} value={percentValue.percents} type="number" min={0} max={100} placeholder={"0"} />
                    </Col>
                    <Col lg="1" className="verCent text-center no-gutters">
                        [%]
                    </Col>
                    <Col lg="3" className="verCent">
                        <Button variant="success" className="fullwidthbutton" onClick={() => startBuyTranche()} disabled={!canBuy}>
                            {t("nakoupit")}
                        </Button>
                    </Col>
                </Row>

            </div>
        </React.Fragment>
    );
};

export default ItemWithPrices;