import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faShoppingCart, faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IProps {}

const LoginPage: React.FC<IProps> = (props) => {
  // debugger;
    return <div className="loadingPage">
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
    </div>;
};

export default LoginPage;
