import React from "react";
import { useTranslation } from "react-i18next";

import Detail from '../../customer/sections/Detail';

interface IProps {}

const Customer: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["modules\\module-one"]);

  return (
    <>
      <div>{t("Name")}</div>
      <div>        
        <Detail />
      </div>
    </>
  );
};

export default Customer;
