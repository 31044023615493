import React, { useEffect, useState } from "react";
import IRepository from "../../data/IRepository";
import { useTranslation } from "react-i18next";
import { Modal, Col, Row, Form, Button, Badge } from "react-bootstrap";
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faShoppingCart, faSpinner, faBug, faChevronLeft, faChevronRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OpenModalTime } from "../envelope/dto/EnvelopeDTO";
import { ModalTimeDTO } from "../modal/dto/ModalTimeDTO";
import { IsSet } from "../modal/dto/ModalTimeDTO";
import moment, { Moment } from "moment";
import "moment/locale/cs";

interface IProps {
    isOpen: boolean;
    onHide: (e: any) => void;
    useremail: string | undefined;
    username: string | undefined;
    userphone: string | undefined;
    clientname: string;
    envelope: string;
    kodbanky: string | undefined;
    obdobi: string | undefined;
}

const ModalTime: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["modules\\dashboard"]);
    const [date, setDate] = useState<Date>(new Date());
    const [response, setResponse] = useState<ModalTimeDTO | undefined>({ datumDotazu: new Date(), datumOdpovedi: new Date(), uzivatel: "", smsEmailResponseText: "" });
    const [choser, setChoser] = useState([true, false]);
    const [set, setSet] = useState<IsSet>({ set: false });

    const sendRequestPrice = async (): Promise<void> => {
        let params = new URLSearchParams();
        var AskDateTime = "";

        setSet({ set: true });

        if (choser[1] == true) {
            AskDateTime = date.toISOString();
        }

        params.set("AskDateTime", AskDateTime);
        params.set("UserEmail", props.useremail ?? "");
        params.set("UserName", props.username ?? "");
        params.set("UserPhone", props.userphone ?? "");
        params.set("ClientName", props.clientname);
        params.set("PurchaseEnvelopeId", props.envelope);
        params.set("Period", props.obdobi ?? "");
        params.set("ExchangeName", props.kodbanky ?? "");

        const axios = require('axios');
        let url = process.env["REACT_APP_FLEXI_API_URL"];

        let data = await axios.get(url + 'AskForPrice', { params: params });
        setResponse(data.data);
        
    }

    const curentDate = () => {
        var dt = new Date();
        dt.setMinutes(new Date().getMinutes() + 5);
        setDate(dt);
    }

    function handleDateChange(e: any) {
        if (moment(e).isValid()) {
            var d = moment(e).toDate();
            setDate(d);

        } else {
            //spatny format datumu
        }
    }

    const resetAndHide = (e: any) => {
        setResponse({ datumDotazu: new Date(), datumOdpovedi: new Date(), uzivatel: "", smsEmailResponseText: "" });
        setSet({ set: false });
        props.onHide(e);
    }

    useEffect(() => {
        curentDate();
    }, []);

    const renderNormalBody = () => {
        return (
            <Row>
                <Col lg="6">
                    <Row>
                        <label className="checkbox reverse">
                            <input type="checkbox" checked={choser[0]} onClick={() => setChoser([!choser[0], !choser[1]])} />
                            <span>
                                {t("priceasap")}
                            </span>
                        </label>
                    </Row>
                </Col>
                <Col lg="6">
                    <Row>
                        <label className="checkbox reverse">
                            <input type="checkbox" checked={choser[1]} onClick={() => setChoser([!choser[0], !choser[1]])} />
                            <span>
                                {t("pricedate")}
                            </span>
                        </label>
                    </Row>
                    <Row className={choser[0] == true ? "faded" : ""}>
                        <Datetime locale="cs" value={date} onChange={(e) => handleDateChange(e)} /> 
                        <span className="mgleft" title={t("pricedatehelp")}><FontAwesomeIcon icon={faInfoCircle} /></span>
                    </Row>
                </Col>
            </Row>
        )
    }

    const renderResponseBody = (modaltime?: ModalTimeDTO) => {
        var responseText = modaltime?.smsEmailResponseText;

        if (modaltime?.resultEmail && modaltime?.resultEmail.error) {
            responseText = modaltime?.resultEmail.message;
        }

        if (modaltime?.resultSMS && modaltime?.resultSMS.error) {
            responseText = modaltime?.resultSMS.message;
        }

        if (modaltime?.resultSMS && modaltime?.resultSMS.error && modaltime?.resultEmail && modaltime?.resultEmail.error) {
            responseText = modaltime?.resultEmail.message + ", " + modaltime?.resultSMS.message;
        }

        return (
            <Row>
                <Col lg="12">
                    <p className="responsetext">
                        {!responseText ? <div className="loadingModal diff"><FontAwesomeIcon icon={faSpinner} className="fa-spin" /></div> : responseText}
                    </p>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Modal size="lg" show={props.isOpen} onHide={(e: any) => resetAndHide(e)} aria-labelledby="modaltime" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="modaltime">
                        {t("modaltime")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Col lg="12">
                        {!set.set ? renderNormalBody() : renderResponseBody(response)}
                    </Col>
                </Modal.Body>
                {!set.set ? <Modal.Footer>
                    <Button variant="primary" onClick={() => sendRequestPrice()}>{t("poptat")}</Button> 
                </Modal.Footer> : <></>}
            </Modal>
        </>
    );
};

export default ModalTime;