import IAppState from "./IAppState";
import { AnyAction } from "redux";
import { Dispatch } from "react";

// ACTIONS
import {MainActionTypes} from "./main/mainActionTypes";

export const validateMainReducer = (
  pState: IAppState,
  pDispatch: Dispatch<AnyAction>
) => {
  // let state = pState.main.tablesState[0];
  // if(!state) return;
  // state.page = 100;
  // pDispatch({type: MainActionTypes.SET_TABLE_STATE, data: state})
};

export const validateAnother = (
  pState: IAppState,
  pDispatch: Dispatch<AnyAction>
) => {
    // let state = pState.main.tablesState[0];
    // if(!state) return;
    // state.page = 200;
    // pDispatch({type: MainActionTypes.SET_TABLE_STATE, data: state})
};
