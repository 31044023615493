import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import styles from "./avatar.module.scss";


interface IProps {
  size: number;
  icon: IconProp;
  iconSize?: number;
  disableShadow?: boolean;   
}

const Avatar: React.FC<IProps> = (props) => {

  const inlineStyle = {
    width: `${props.size}px`,
    height: `${props.size}px`,
    fontSize: props.iconSize !== undefined ? `${props.iconSize}rem` : '1.25rem'
  }

  const getClasses = (disabaleShadow?: boolean) => {
    let shadow = disabaleShadow !== undefined ? " shadow-none" : "";
    return `${styles.avatar}${shadow}`;
  }

  return (
    <div className={getClasses(props.disableShadow)} style={inlineStyle}>
      <FontAwesomeIcon icon={props.icon} />
    </div>
  ) 
};

export default Avatar;
