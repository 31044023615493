// basic package
import { library } from '@fortawesome/fontawesome-svg-core';

// free solid icons
import { 
  faComment,
  faEnvelope, 
  faBars, 
  faBell, 
  faUser,
  faQuestionCircle, 
  faCheckCircle, 
  faExclamationCircle, 
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

// free regular icons
import { 
  faComment as faCommentR 
} from "@fortawesome/free-regular-svg-icons";

// add icons to library
library.add(
  faComment,
  faCommentR,
  faEnvelope,
  faBars,
  faBell,
  faUser,
  faQuestionCircle, 
  faCheckCircle, 
  faExclamationCircle, 
  faInfoCircle
);