import React from "react";
import { Route } from "react-router-dom";

import LogoutPage from "./LogoutPage";
import CallbackPage from "./CallbackPage";
import SilentRenewPage from "./SilentRenewPage";

interface IProps {}

const LoginRoutes: React.FC<IProps> = (props) => {
  return (
    <>
      <Route exact path="/logout" component={LogoutPage} />
      <Route exact path="/callback" component={CallbackPage} />
      <Route exact path={"/silent_renew"} component={SilentRenewPage} />
    </>
  );
};

export default LoginRoutes;
