import React, { useEffect, useState } from "react";
import DataQuery from "../../data/DataQuery";
import IRepository from "../../data/IRepository";
import { useTranslation } from "react-i18next";
import { Modal, Col, Row, Form, Button, Badge, Accordion, Card } from "react-bootstrap";
import { FormulaListDTO } from "../formula/dto/FormulaListDTO";
import { PeriodList } from "../formula/dto/FormulaListDTO";
import { PeriodToBuyDTO } from "../modal/dto/PeriodToBuyDTO";
import { BuyTrancheDTO } from "../buyTranche/dto/BuyTrancheDTO";
import { Formula } from "../buyTranche/dto/BuyTrancheDTO";
import { Period } from "../buyTranche/dto/BuyTrancheDTO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemWithPrices from "../itemWithPrices/ItemWithPrices";
import { KodBankyDTO } from "../modal/dto/PeriodDTO";
import Tranche from "../buyTranche/Tranche";
import { faPlus, faMinus, faShoppingCart, faSpinner, faChevronDown, faBug } from "@fortawesome/free-solid-svg-icons";
import Detail from "../customer/sections/Detail";

interface IProps {
    periodToBuy?: PeriodToBuyDTO;
    isRequested: number;
    mail: string;
    envelope: string;
    komodita: string;
    kodBanky: string;
}

const Formul: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["modules\\dashboard"]);
    const [formule, setFormule] = useState<FormulaListDTO | undefined>();
    const [tranche, setTranche] = useState<BuyTrancheDTO | undefined>();
    const [isBuy, setIsBuy] = useState<boolean>(false);

    const getPrices = async (): Promise<void> => {
        let params = props.periodToBuy;
        const axios = require('axios');
        let url = process.env["REACT_APP_FLEXI_API_URL"];
        let data = await axios.post(url + 'GetPrice', params);

        setFormule(data.data);
    };

    const onSelectItemHandle = (detailInfo: PeriodList, formulaName: string, formulaDisplayName:string, value?: number) => {
        var peakload = (props.komodita == "EE") ? detailInfo.pricePeakLoad : 0;

        let period = new Array<Period>({
            periodCode: detailInfo.periodCode,
            priceBaseLoad: detailInfo.priceBaseLoad,
            pricePeakLoad: peakload,
            overAmountRemAllowed: detailInfo.overAmountRemAllowed,
            exchangeRate: detailInfo.exchangeRate,
            dateLoadedPrice: detailInfo.dateLoadedPrice,
            kindDerivationPrice: detailInfo.kindDerivationPrice,
            calculatedPrice: detailInfo.calculatedPrice,
            amount: (value) ? +value : 0,
            purchaseManner: "Hodnota"
        });

        let formula = new Array<Formula>({ formulaName: formulaName, formulaDisplayName: formulaDisplayName, periodList: period });
        let trancheToBuy: BuyTrancheDTO = { email: props.mail, purchaseEnvelopeId: props.envelope, formulaList: formula };

        setTranche(trancheToBuy);
        setIsBuy(true);
    };

    useEffect(() => {
        getPrices();
    }, [props.isRequested]);

    const renderModalBody = (pFormules?: FormulaListDTO) => {
        if (!pFormules)
            return (
                <div className="loadingModal">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    <p>{t("loadingFormuls")}</p>
                </div>
            );


        if (pFormules.messageError) {
            return (
                <div className="errorModal">
                    <FontAwesomeIcon icon={faBug} />
                    <p>{t("error")}
                        <br />
                        <small>
                            {pFormules.messageError}
                        </small>
                    </p>
                </div>
            );
        }

        return pFormules.formulaList.map((perioda, i) => {
            let YearsSet = new Array<PeriodList>();
            let QuartSet = new Array<PeriodList>();
            let MonthsSet = new Array<PeriodList>();
            let YearsBest = new Array<PeriodList>();
            let QuartBest = new Array<PeriodList>();
            let MonthsBest = new Array<PeriodList>();


            perioda.periodList.map((bloky: PeriodList) => {
                if (bloky.periodCode.includes("R") && bloky.kindDerivationPrice == "Settlement") {
                    YearsSet.push(bloky);
                }
                if (bloky.periodCode.includes("Q") && bloky.kindDerivationPrice == "Settlement") {
                    QuartSet.push(bloky);
                }
                if (bloky.periodCode.includes("M") && bloky.kindDerivationPrice == "Settlement") {
                    MonthsSet.push(bloky);
                }

                if (bloky.periodCode.includes("R") && bloky.kindDerivationPrice != "Settlement") {
                    YearsBest.push(bloky);
                }
                if (bloky.periodCode.includes("Q") && bloky.kindDerivationPrice != "Settlement") {
                    QuartBest.push(bloky);
                }
                if (bloky.periodCode.includes("M") && bloky.kindDerivationPrice != "Settlement") {
                    MonthsBest.push(bloky);
                }
            });

            let OrderedOffersSetLeft = [...YearsSet, ...QuartSet];
            let OrderedOffersSetRight = [...MonthsSet];

            let OrderedOffersBestLeft = [...YearsBest, ...QuartBest];
            let OrderedOffersBestRight = [...MonthsBest];

            const PlanListSetLeft = OrderedOffersSetLeft.map((nakupy, y) => {
                return (
                    <React.Fragment>
                        <Col lg="12">
                            <ItemWithPrices obchodniDen={pFormules.statusDne.obchodniDen} onSelectItem={onSelectItemHandle} formulaName={perioda.formulaName} formulaDisplayName={perioda.formulaDisplayName} detailInfo={nakupy} kodBanky={props.kodBanky} komodita={props.komodita} />
                        </Col>
                    </React.Fragment>
                );
            });
            const PlanListSetRight = OrderedOffersSetRight.map((nakupy, y) => {
                return (
                    <React.Fragment>
                        <Col lg="12">
                            <ItemWithPrices obchodniDen={pFormules.statusDne.obchodniDen} onSelectItem={onSelectItemHandle} formulaName={perioda.formulaName} formulaDisplayName={perioda.formulaDisplayName} detailInfo={nakupy} kodBanky={props.kodBanky} komodita={props.komodita} />
                        </Col>
                    </React.Fragment>
                );
            });

            const PlanListBestLeft = OrderedOffersBestLeft.map((nakupy, y) => {
                return (
                    <React.Fragment>
                        <Col lg="12">
                            <ItemWithPrices obchodniDen={pFormules.statusDne.obchodniDen} onSelectItem={onSelectItemHandle} formulaName={perioda.formulaName} formulaDisplayName={perioda.formulaDisplayName} detailInfo={nakupy} kodBanky={props.kodBanky} komodita={props.komodita} />
                        </Col>
                    </React.Fragment>
                );
            });
            const PlanListBestRight = OrderedOffersBestRight.map((nakupy, y) => {
                return (
                    <React.Fragment>
                        <Col lg="12">
                            <ItemWithPrices onSelectItem={onSelectItemHandle} obchodniDen={pFormules.statusDne.obchodniDen} formulaName={perioda.formulaName} formulaDisplayName={perioda.formulaDisplayName} detailInfo={nakupy} kodBanky={props.kodBanky} komodita={props.komodita} />
                        </Col>
                    </React.Fragment>
                );
            });


            return (
                <Accordion defaultActiveKey="0" className="col-lg-12">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey={i.toString()} className="text-center">
                            <FontAwesomeIcon icon={faChevronDown} className="icoleft" />
                            <strong>{perioda.formulaDisplayName}</strong>
                            <FontAwesomeIcon icon={faChevronDown} className="icoright" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i.toString()}>
                            <Card.Body>
                                <Row>
                                    <Col lg="12">
                                        <Row>
                                            <Col lg="6">
                                                {PlanListSetLeft}
                                            </Col>
                                            <Col lg="6">
                                                {PlanListSetRight}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                {PlanListBestLeft}
                                            </Col>
                                            <Col lg="6">
                                                {PlanListBestRight}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            );
        });

    };

    return (
        <React.Fragment>
            {(!isBuy) ? renderModalBody(formule) : <Tranche trancheToBuy={tranche} />}
        </React.Fragment>
    );
};

export default Formul;