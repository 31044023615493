import React, { useEffect, useState } from "react";
import DataQuery from "../../data/DataQuery";
import IRepository from "../../data/IRepository";
import { useTranslation } from "react-i18next";
import { Modal, Col, Row, Form, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    periodCode: string;
    kindDerivationPrice: string;
    onSelectItem: (code: string, baseload: number, peakload: number, exchange: number, date: string, kind: string, addOrRemove?: number) => void;
    whatsSelected: boolean[];
    whatsShown: boolean;
    rerender: boolean;
    msgBaseLoad: string;
    msgPeakLoad: string;
    baseLoad?: null;
    datumBaseLoad: string;
    datumPeakLoad: string;
    datumkurzu: string;
    peakLoad?: null;
    exchange: number;
    datum: string;
    komodita: string;
    banka: string;    
    textInfoBaseLoad: string;
    zdaPlatnaCenaBaseLoad: boolean;
    zdaPlatnaCenaPeakLoad: boolean;
    onModalTime: (isOpen: boolean, obdobi: string) => void;
}

const Item: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["modules\\dashboard"]);
    const [selected, setSelected] = useState(false);


    function setSelectedAndSend(set: boolean, code: string, baseload: number, peakload: number, exchange: number, date: string, kind: string) {
        setSelected(set);
        let adding = (set) ? 1 : 0;
        props.onSelectItem(code, baseload, peakload, exchange, date, kind, adding);
    }

    useEffect(() => {


    }, [props.rerender]);

    const toggleModalTime = (e: any) => {       
        props.onModalTime(true, props.periodCode);
    }

    return (
        <>
            <div className={"balicek bg-light " + (selected ? " selected" : "") + (props.baseLoad && props.zdaPlatnaCenaBaseLoad ? (props.baseLoad > 2) ? "" : " faded" : " faded") + ((props.kindDerivationPrice == "Settlement") ? ((props.whatsSelected[1] == true) ? "" : " hidden") : ((props.whatsSelected[0] == true) ? "" : " hidden")) + ((props.whatsShown == true) ? ((props.baseLoad && props.baseLoad > 2) ? "" : " hidden") : "")}>
                <Row className="head">
                    <Col lg="9">
                        <h2>{props.periodCode} <Badge variant="primary">{props.kindDerivationPrice}</Badge></h2>
                    </Col>
                    <Col lg="3" className="d-flex justify-content-end">
                        {!props.zdaPlatnaCenaBaseLoad && props.kindDerivationPrice == "BestAsk" && new Date(props.datumBaseLoad).toLocaleDateString() != "1. 1. 1"  ?
                            <Button onClick={(e:any) => toggleModalTime(e)} variant="primary" className="smolBtn btn btn-success">{t("poptatcenu")}</Button> : <></>}

                        {(props.baseLoad && props.zdaPlatnaCenaBaseLoad) ? (props.baseLoad > 2) ? <label className="checkbox">
                            <input type="checkbox" checked={selected} onChange={() => setSelectedAndSend(!selected, props.periodCode, (props.baseLoad) ? Number(props.baseLoad) : 1.1, (props.komodita == "EE") ? ((props.peakLoad) ? Number(props.peakLoad) : 1.1) : 0, props.exchange, props.datumBaseLoad, props.kindDerivationPrice)} />

                            <span>
                                Vybrat
                            </span>
                        </label> : <></> : <></>}
                    </Col>
                </Row>
                <Row className="body">
                    <Col lg={(props.komodita == "EE") ? 4 : 4}>
                        <Form.Group>
                            <Form.Label><strong>{t("baseload")}</strong></Form.Label>
                            <input type="hidden" value={props.msgBaseLoad} />
                            <input type="hidden" value={props.datumBaseLoad} />
                            <p className={props.zdaPlatnaCenaBaseLoad ? "" : "notactualprice"}>{(props.baseLoad) ? Number(props.baseLoad).toFixed(3).replace('.', ',') + " [EUR/MWh]" : "-"}</p>
                        </Form.Group>
                    </Col>

                    {/*(props.komodita == "EE") ? <Col lg="3">
                        <Form.Group>
                            <Form.Label><strong>PeakLoad</strong></Form.Label>
                            <input type="hidden" value={props.msgPeakLoad} />
                            <input type="hidden" value={props.datumPeakLoad}>
                            <p>{(props.peakLoad) ? Number(props.peakLoad)+" [EUR/MWh]" : ""}</p>
                        </Form.Group>
                    </Col> : <></>*/}

                    <Col lg={(props.komodita == "EE") ? 4 : 4}>
                        <Form.Group>
                            <Form.Label><strong>{t("kurz")}</strong></Form.Label>
                            <input type="hidden" value={props.datumkurzu} />
                            <p>{props.exchange.toFixed(2).replace('.', ',')} [CZK/EUR]</p>
                        </Form.Group>
                    </Col>
                    <Col lg={(props.komodita == "EE") ? 4 : 4}>
                        <Form.Group>
                            <Form.Label><strong>{t("datumceny")}</strong></Form.Label>
                            <p>{(new Date(props.datumBaseLoad).toLocaleDateString() == "1. 1. 1") ? "-" : new Date(props.datumBaseLoad).toLocaleDateString()}{(new Date(props.datumBaseLoad).getHours() != 0) ? " "+ new Date(props.datumBaseLoad).getHours() + ":"
                                + (new Date(props.datumBaseLoad).getMinutes() < 10 ? '0' : '')
                                + new Date(props.datumBaseLoad).getMinutes() + ":"
                                + (new Date(props.datumBaseLoad).getSeconds() < 10 ? '0' : '')
                                + new Date(props.datumBaseLoad).getSeconds() : props.kindDerivationPrice == "Settlement" && (new Date(props.datumBaseLoad).toLocaleDateString() != "1. 1. 1") ? ", " + t("vecer") : ""}

                                {(new Date(props.datumBaseLoad).toLocaleDateString() != "1. 1. 1" && props.kindDerivationPrice == "Settlement" && !props.zdaPlatnaCenaBaseLoad) ? <span className="mgleft" title={props.textInfoBaseLoad}><FontAwesomeIcon icon={faInfoCircle} /></span> : <></>}

                                {(new Date(props.datumBaseLoad).toLocaleDateString() != "1. 1. 1" && props.kindDerivationPrice == "BestAsk") ? <span className="mgleft" title={props.zdaPlatnaCenaBaseLoad ? t("bestaskmessage") : t("bestaskmessage") + " " + props.textInfoBaseLoad}><FontAwesomeIcon icon={faInfoCircle} /></span> : <></>}

                            </p>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Item;