import React from "react";
import UserManager from "./UserManager";

interface IProps {}

const LogoutPage: React.FC<IProps> = (props) => {
  return (
    <>
      Logging out...
      {UserManager.signoutRedirect()}
    </>
  );
};

export default LogoutPage;
