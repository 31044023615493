import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import CustomTable from '../../shared/components/table/CustomTable';

interface IProps {}

const Detail: React.FC<IProps> = (props) => {

  const tCustomer = useTranslation(["customer\\customer"]).t;

  useEffect(() => {

  }, [])

  const getCustomer = async (): Promise<void> => {
    
    }

  return (
    <>          
      <CustomTable 
      headData={[
        tCustomer('CustomerId'),
        tCustomer('CustomerNumber'),
        tCustomer('CustomerName'),
        tCustomer('CustomerType'),
        tCustomer('CompanyRegistrationNumber'),
        tCustomer('TaxId'),
        tCustomer('Taxpayer'),
        tCustomer('BusinessRegisterSection'),
        tCustomer('BusinessRegisterInsertNumber'),
        tCustomer('BusinessRegisterWrittenIn'),
        tCustomer('BusinessRegisterScopeOfBusiness'),
        tCustomer('RegionalCourtCode'),
        tCustomer('RegionalCourtName'),
        tCustomer('LegalFormCode'),
        tCustomer('LegalFormRegister'),
        tCustomer('LegalFormName'),
        tCustomer('DateOfTemination'),
        tCustomer('FirstName'),
        tCustomer('LastName'),
        tCustomer('TitleBeforeName'),
        tCustomer('TitleAfterName'),
        tCustomer('DateOfBirth'),
        tCustomer('PersonalIdentificationNumber'),
        tCustomer('Street'),
        tCustomer('LandRegistryNumber'),
        tCustomer('EvidenceNumber'),
        tCustomer('HouseNumber'),
        tCustomer('City'),
        tCustomer('CityPart'),
        tCustomer('Zipcode'),
        tCustomer('CountryCode'),
        tCustomer('CountryName')]}
      />
    </>
  );
};

export default Detail;
