import React from "react";

import { Table } from "react-bootstrap";

interface IProps {
  headData: Array<string>;
  rowData?: Array<string>;
}

const CustomTable: React.FC<IProps> = (props) => {
  const renderHead = (headData: Array<string>) => {
    return (
      <thead>
        <tr>
          {headData.map((val, index) => (
            <th>{val}</th>
          ))}
        </tr>
      </thead>
    );
  };

  return (
    <>
      <Table>{renderHead(props.headData)}</Table>
    </>
  );
};

export default CustomTable;
