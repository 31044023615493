import React, { useEffect, useState } from "react";
import IRepository from "../../data/IRepository";
import { useTranslation } from "react-i18next";
import { Modal, Col, Row, Form, Button, Badge } from "react-bootstrap";
import Item from "../item/Item";
import Formul from "../formula/Formul";
import ModalTime from "../modal/ModalTime";
import { PeriodDTO } from "../modal/dto/PeriodDTO";
import { KodBankyDTO } from "../modal/dto/PeriodDTO";
import { PeriodToBuyDTO } from "../modal/dto/PeriodToBuyDTO";
import { Period } from "../modal/dto/PeriodToBuyDTO";
import { CenaEntity } from "../modal/dto/PeriodDTO";
import { ShowOnlyPrices } from "../modal/dto/PeriodDTO";
import { UserState } from "redux-oidc";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "infrastructure/store/IAppState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faShoppingCart, faSpinner, faBug, faSync } from "@fortawesome/free-solid-svg-icons";
import { OpenModalTime } from "../envelope/dto/EnvelopeDTO";

interface IProps {
    isOpen: boolean;
    mail: string;
    envelope: string;
    rok: number;
    komodita: string;
    clientname: string;
    onHide: (e: any, mail: string, envelope: string, rok: number, komodita: string, clientname: string, obchodujeme: boolean, zbyva: number) => void;
}

const ModalBuy: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["modules\\dashboard"]);
    const [show, setShow] = useState(false);
    const [prices, setPrices] = useState(0);
    const [selectAll, setSelectAll] = useState([true, true]);
    const [showOnlyPrices, setShowOnlyPrices] = useState<ShowOnlyPrices>({ show: false });
    const [period, setPeriod] = useState<PeriodDTO | undefined>();
    const [kodBanky, setKodBanky] = useState<KodBankyDTO | undefined>();

    const [isOpenModalTime, setIsOpenModalTime] = useState<OpenModalTime>({ isOpen: false, obdobi: undefined });

    const [periodToBuy, setPeriodToBuy] = useState<PeriodToBuyDTO | undefined>();
    let userState = useSelector<IAppState, UserState>((store) => store.oidc);
    const user = userState.user;
    const [fresh, setFresh] = useState(0);

    function refresh() {
        let num = fresh + 1;
        setFresh(num);
    }

    const handleOnChangeSelect = (periodCode: string, baseload: number, peakload: number, exchange: number, date: string, kind: string, addOrRemove?: number) => {
        let helpArray: Period = { periodCode: periodCode, priceBaseLoad: baseload, pricePeakLoad: peakload, exchangeRate: exchange, dateLoadedPrice: date, kindDerivationPrice: kind };

        var oldArray = periodToBuy?.periodList;

        if (oldArray) {
            let hasMagenicVendor = oldArray.some(oldArray => oldArray['periodCode'] === periodCode && oldArray['kindDerivationPrice'] === kind);

            if (!hasMagenicVendor) { //p�id� do pole
                if (addOrRemove == 1) {
                    oldArray.push(helpArray);
                    setPeriodToBuy({ email: props.mail, purchaseEnvelopeId: props.envelope, periodList: oldArray });
                }
            } else {
                if (addOrRemove == 0) {
                    for (var i = 0; i < oldArray.length; i++) {
                        if (oldArray[i].periodCode === periodCode && oldArray[i].kindDerivationPrice === kind) {
                            oldArray.splice(i, 1);
                            break;
                        } // odebere z pole
                    }

                    setPeriodToBuy({ email: props.mail, purchaseEnvelopeId: props.envelope, periodList: oldArray });
                }//aktualizace state s vybran�mi bal��ky k nacen�n�
            }

        }
    }

    const handleTimeModal = (isOpen: boolean, obdobi: string) => {
        setIsOpenModalTime({ isOpen: true, obdobi: obdobi }); 
    }

    const toggleModalTime = (e: any) => {
        setIsOpenModalTime({ isOpen: false, obdobi: undefined }); 
    }

    const getPeriod = async (): Promise<void> => {
        let params = new URLSearchParams();

        //parametry u�ivatele
        params.set("email", props.mail);
        params.set("purchaseEnvelopeId", props.envelope);
        params.set("rok", props.rok.toString());
        params.set("mena", "CZK/EUR");

        const axios = require('axios');
        let url = process.env["REACT_APP_FLEXI_API_URL"];

        let data = await axios.get(url + 'GetPeriodWithPrice', { params: params });

        setPeriod(data.data);

        if (data.data.cena.length > 0) {
            setKodBanky({
                kodBanky: (data.data.cena[0].nazevBurzy == "EEX") ? (props.komodita == "EE") ? "EEX" : "NCG" : data.data.cena[0].nazevBurzy
            });
        }

    };

    useEffect(() => {
        setPeriod(undefined);
        //setSelectAll([true, true]);
        setPrices(0);
        setKodBanky({
            kodBanky: ""
        });

        if (props.mail) {
            setPeriodToBuy({ email: props.mail, purchaseEnvelopeId: props.envelope, periodList: [] });
            getPeriod();
        }
    }, [props.isOpen, fresh]);


    const renderModalBodySetLeft = (periods?: PeriodDTO) => {
        if (!periods)
            return;

        let YearsSet = new Array<CenaEntity>();
        let QuartSet = new Array<CenaEntity>();

        if (periods.messageError)
            return;


        periods.cena.map((perioda, index) => {
            if (perioda.obdobi.includes("R") && perioda.zpusobStanoveniCeny == "Settlement") {
                YearsSet.push(perioda);
            }

            if (perioda.obdobi.includes("Q") && perioda.zpusobStanoveniCeny == "Settlement") {
                QuartSet.push(perioda);
            }
        });

        let OrderedOffers = [...YearsSet, ...QuartSet];
        var selected = selectAll;

        return OrderedOffers.map((perioda, index) => {
            return (
                <React.Fragment>
                    <Col lg="12">
                        <Item onSelectItem={handleOnChangeSelect}
                            banka={perioda.nazevBurzy}
                            datumkurzu={periods.datumKurzu}
                            datum={periods.datumOdpovedi}
                            datumBaseLoad={perioda.dateTimeBaseLoad}
                            datumPeakLoad={perioda.dateTimePeakLoad}
                            msgBaseLoad={perioda.messageBaseLoad}
                            msgPeakLoad={perioda.messagePeakLoad}
                            baseLoad={perioda.cenaBaseLoad}
                            peakLoad={perioda.cenaPeakLoad}
                            exchange={periods.kurz}
                            periodCode={perioda.obdobi}
                            kindDerivationPrice={perioda.zpusobStanoveniCeny}
                            whatsSelected={selected}
                            whatsShown={showOnlyPrices.show}
                            rerender={show}
                            komodita={props.komodita}
                            textInfoBaseLoad={perioda.textInfoBaseLoad}
                            zdaPlatnaCenaBaseLoad={perioda.zdaPlatnaCenaBaseLoad}
                            zdaPlatnaCenaPeakLoad={perioda.zdaPlatnaCenaPeakLoad}
                            onModalTime={handleTimeModal}
                        />
                    </Col>
                </React.Fragment>
            );
        });
    };

    const renderModalBodySetRight = (periods?: PeriodDTO) => {
        if (!periods)
            return;

        let MonthsSet = new Array<CenaEntity>();

        if (periods.messageError)
            return;

        periods.cena.map((perioda, index) => {
            if (perioda.obdobi.includes("M") && perioda.zpusobStanoveniCeny == "Settlement") {
                MonthsSet.push(perioda);
            }
        });

        let OrderedOffers = [...MonthsSet];
        var selected = selectAll;

        return OrderedOffers.map((perioda, index) => {
            return (
                <React.Fragment>
                    <Col lg="12">
                        <Item onSelectItem={handleOnChangeSelect}
                            banka={perioda.nazevBurzy}
                            datumkurzu={periods.datumKurzu}
                            datum={periods.datumOdpovedi}
                            datumBaseLoad={perioda.dateTimeBaseLoad}
                            datumPeakLoad={perioda.dateTimePeakLoad}
                            msgBaseLoad={perioda.messageBaseLoad}
                            msgPeakLoad={perioda.messagePeakLoad}
                            baseLoad={perioda.cenaBaseLoad}
                            peakLoad={perioda.cenaPeakLoad}
                            exchange={periods.kurz}
                            periodCode={perioda.obdobi}
                            kindDerivationPrice={perioda.zpusobStanoveniCeny}
                            whatsSelected={selected}
                            whatsShown={showOnlyPrices.show}
                            rerender={show}
                            komodita={props.komodita}
                            textInfoBaseLoad={perioda.textInfoBaseLoad}
                            zdaPlatnaCenaBaseLoad={perioda.zdaPlatnaCenaBaseLoad}
                            zdaPlatnaCenaPeakLoad={perioda.zdaPlatnaCenaPeakLoad}
                            onModalTime={handleTimeModal}
                        />
                    </Col>
                </React.Fragment>
            );
        });
    };

    const renderModalBodyBestLeft = (periods?: PeriodDTO) => {
        if (!periods)
            return;

        if (periods.messageError)
            return;

        let YearsSet = new Array<CenaEntity>();
        let QuartSet = new Array<CenaEntity>();

        periods.cena.map((perioda, index) => {
            if (perioda.obdobi.includes("R") && perioda.zpusobStanoveniCeny != "Settlement") {
                YearsSet.push(perioda);
            }

            if (perioda.obdobi.includes("Q") && perioda.zpusobStanoveniCeny != "Settlement") {
                QuartSet.push(perioda);
            }
        });

        let OrderedOffers = [...YearsSet, ...QuartSet];
        var selected = selectAll;

        return OrderedOffers.map((perioda, index) => {
            return (
                <React.Fragment>
                    <Col lg="12">
                        <Item onSelectItem={handleOnChangeSelect}
                            banka={perioda.nazevBurzy}
                            datumkurzu={periods.datumKurzu}
                            datum={periods.datumOdpovedi}
                            datumBaseLoad={perioda.dateTimeBaseLoad}
                            datumPeakLoad={perioda.dateTimePeakLoad}
                            msgBaseLoad={perioda.messageBaseLoad}
                            msgPeakLoad={perioda.messagePeakLoad}
                            baseLoad={perioda.cenaBaseLoad}
                            peakLoad={perioda.cenaPeakLoad}
                            exchange={periods.kurz}
                            periodCode={perioda.obdobi}
                            kindDerivationPrice={perioda.zpusobStanoveniCeny}
                            whatsSelected={selected}
                            whatsShown={showOnlyPrices.show}
                            rerender={show}
                            textInfoBaseLoad={perioda.textInfoBaseLoad}
                            zdaPlatnaCenaBaseLoad={perioda.zdaPlatnaCenaBaseLoad}
                            zdaPlatnaCenaPeakLoad={perioda.zdaPlatnaCenaPeakLoad}
                            komodita={props.komodita}
                            onModalTime={handleTimeModal}
                        />
                    </Col>
                </React.Fragment>
            );
        });
    };

    const renderModalBodyBestRight = (periods?: PeriodDTO) => {
        if (!periods)
            return;

        let MonthsSet = new Array<CenaEntity>();

        if (periods.messageError)
            return;

        periods.cena.map((perioda, index) => {
            if (perioda.obdobi.includes("M") && perioda.zpusobStanoveniCeny != "Settlement") {
                MonthsSet.push(perioda);
            }
        });

        let OrderedOffers = [...MonthsSet];
        var selected = selectAll;

        return OrderedOffers.map((perioda, index) => {
            return (
                <React.Fragment>
                    <Col lg="12">
                        <Item onSelectItem={handleOnChangeSelect}
                            banka={perioda.nazevBurzy}
                            datumkurzu={periods.datumKurzu}
                            datum={periods.datumOdpovedi}
                            datumBaseLoad={perioda.dateTimeBaseLoad}
                            datumPeakLoad={perioda.dateTimePeakLoad}
                            msgBaseLoad={perioda.messageBaseLoad}
                            msgPeakLoad={perioda.messagePeakLoad}
                            baseLoad={perioda.cenaBaseLoad}
                            peakLoad={perioda.cenaPeakLoad}
                            exchange={periods.kurz}
                            periodCode={perioda.obdobi}
                            kindDerivationPrice={perioda.zpusobStanoveniCeny}
                            whatsSelected={selected}
                            whatsShown={showOnlyPrices.show}
                            rerender={show}
                            textInfoBaseLoad={perioda.textInfoBaseLoad}
                            zdaPlatnaCenaBaseLoad={perioda.zdaPlatnaCenaBaseLoad}
                            zdaPlatnaCenaPeakLoad={perioda.zdaPlatnaCenaPeakLoad}
                            komodita={props.komodita}
                            onModalTime={handleTimeModal}
                        />
                    </Col>
                </React.Fragment>
            );
        });
    };

    function selectThisAll(what: string) {
        var selected = selectAll;

        if (what == "bestask") {
            selected[0] = !selected[0];
        } else {
            selected[1] = !selected[1];
        }

        setSelectAll(selected);
        setShow(!show);
    }

    const renderChecksSelectors = () => {
        return (
            <Col lg="12" className="selecteris">
                <label className="checkbox">
                    <input type="checkbox" checked={showOnlyPrices.show} onChange={() => setShowOnlyPrices({ show: !showOnlyPrices.show })} />
                    <span>
                        {t("jenscenou")}
                    </span>
                </label>

                <label className="checkbox">
                    <input type="checkbox" checked={selectAll[0]} onChange={() => selectThisAll("bestask")} />
                    <span>
                        BestAsk
                    </span>
                </label>

                <label className="checkbox">
                    <input type="checkbox" checked={selectAll[1]} onChange={() => selectThisAll("settlement")} />
                    <span>
                        Settlement
                    </span>
                </label>
            </Col>
        )
    }

    const renderBody = (periods?: PeriodDTO) => {
        if (!periods)
            return (
                <div className="loadingModal">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    <p>{t("loadingPeriods")}</p>
                </div>
            );

        if (periods.messageError) {
            return (
                <div className="errorModal">
                    <FontAwesomeIcon icon={faBug} />
                    <p>{t("error")}
                        <br />
                        <small>
                            {periods.messageError}
                        </small>
                    </p>
                </div>
            );
        }

        return (
            <Col lg="12">
                <Row>
                    <Col lg="6">
                        {renderModalBodySetLeft(period)}
                    </Col>
                    <Col lg="6">
                        {renderModalBodySetRight(period)}
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        {renderModalBodyBestLeft(period)}
                    </Col>
                    <Col lg="6">
                        {renderModalBodyBestRight(period)}
                    </Col>
                </Row>
            </Col>
        )
    }



    return (
        <>
            <Modal show={props.isOpen} onHide={(e: any) => props.onHide(e, "", "", 0, "", "", true, 1)} dialogClassName="modal-90w" aria-labelledby="modalbuy">
                <Modal.Header closeButton>
                    <Modal.Title id="modalbuy">
                        {t("nakuppro")} <strong>{props.envelope} - {props.clientname} ({props.rok})</strong>
                    </Modal.Title>

                    {(kodBanky?.kodBanky) ? <div className="kodbanky">
                        <strong>{kodBanky?.kodBanky}</strong>
                    </div> : <></>}
                </Modal.Header>
                <Modal.Body className="modalFullWidthMax">
                    <Form>
                        <Col lg="12">
                            <Row>
                                {(prices == 0) ? renderChecksSelectors() : <></>}
                            </Row>
                            <Row>
                                {(prices == 0) ? renderBody(period) : <Formul kodBanky={(kodBanky?.kodBanky) ? kodBanky?.kodBanky : ""} mail={props.mail} envelope={props.envelope} periodToBuy={periodToBuy} isRequested={prices} komodita={props.komodita} />}
                            </Row>
                        </Col>
                    </Form>
                </Modal.Body>
                {(prices == 0) ? <Modal.Footer>
                    <span className="refreshIcon left" onClick={refresh}>
                        <FontAwesomeIcon icon={faSync} /> <span>{t("obnovit")}</span>
                    </span>
                    <Button variant="primary" onClick={() => setPrices(1)} disabled={periodToBuy?.periodList?.length == 0}>{t("ziskatceny")}</Button>
                </Modal.Footer> : <></>}
            </Modal>


            <ModalTime
                useremail={user?.profile.email}
                username={user?.profile.name}
                userphone={user?.profile.phone_number}
                clientname={props.clientname}
                envelope={props.envelope}
                kodbanky={kodBanky?.kodBanky}
                obdobi={isOpenModalTime?.obdobi}
                isOpen={isOpenModalTime?.isOpen}
                onHide={toggleModalTime}
            />
        </>
    );
};

export default ModalBuy;