import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import IAppState from "./infrastructure/store/IAppState";

// Oidc
import LoginPage from "./infrastructure/oidc/LoginPage";
import UserManager from "./infrastructure/oidc/UserManager";
import { USER_FOUND } from "redux-oidc";
import { UserState } from "redux-oidc";
import LoginRoutes from "./infrastructure/oidc/LoginRoutes";

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/main.scss";
import Layout from "./features/shared/layout/Layout";

// FontAwesome icons
import "./fontawesome";

// Modules
import Dashboard from "./features/shared/modules/Dashboard";
import Customer from "./features/shared/modules/Customer";

const App: React.FC = () => {
    let userState = useSelector<IAppState, UserState>((store) => store.oidc);

    const dispatch = useDispatch();

    useEffect(() => {
         getUser();
    }, [userState.user]);

    // Pokus�m se na��st u�ivatele z tokenu
    const getUser = async () => {
        if (
            userState.isLoadingUser || // u�ivatel se na��t�
            userState.user || // u�ivatel je na�ten�
            window.location.pathname === "/callback" || // prob�h� callback z IS
            window.location.pathname === "/logout" // prob�h� odhla�ov�n�
        )
            return;

        await UserManager.getUser().then((user) => {
            if (user && !user.expired) {
                // Nastav�m token do hlavi�ky axiosu pro v�echna vol�n�
                //axios.defaults.headers.common["Authorization"] = "Bearer " + user.access_token;

                // nastavim Usera do store
                dispatch({ type: USER_FOUND, payload: { ...user } });
            } else {
                UserManager.signinRedirect();
            }
        });
    };


    if (!userState.user) {
        return (
            // Pokud nejsem p�ihl�en�, nezobrazuju Layout
            <Switch>
                <Route exact path="/" component={LoginPage} />
                <LoginRoutes />
            </Switch>
        );
    } else {
        return (
            <Layout>
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/customer" component={Customer} />
                    <LoginRoutes />
                </Switch>
            </Layout >
        );
    }


};

export default App;
