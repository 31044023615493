import React from "react";
import { CallbackComponent } from "redux-oidc";
import UserManager from "./UserManager";
import { useHistory } from "react-router-dom";

interface IProps {}

const CallbackPage: React.FC<IProps> = () => {
  const routerHistory = useHistory();
  return (
    <CallbackComponent
      userManager={UserManager}
      successCallback={() => routerHistory.push("/")}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
};

export default CallbackPage;
