import React, { ReactElement } from "react";

import { DeviceTypeEnum } from "../../../enums/DeviceTypeEnum";

import styles from "./topbarlogo.module.scss";

const logoImg = require("images/clients/" + process.env.REACT_APP_CLIENT_NAME + '/logo.png');

interface IProps { 
    type: DeviceTypeEnum;
}
  
const TopBarLogo : React.FC<IProps> = (props) => {
  
  let logoClass = (type: string) => {
    switch (type) {
      case DeviceTypeEnum.MOBILE:
        return `d-md-none ${styles.topbarLogo}`;
      case DeviceTypeEnum.DESKTOP:
        return `d-none d-md-flex navbar-brand ${styles.topbarLogo}`;
      default:
        return "d-none";
    }
  }

  return (
    <a className={logoClass(props.type)} href="/">     
          <img src={logoImg} alt="Logo" className={styles.topbarLogoImg} />
    </a>
  );
}; 

export default TopBarLogo;