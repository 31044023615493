import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from "redux";

import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";

import { composeWithDevTools } from "redux-devtools-extension";

// Middlewares
import createSagaMiddleware from "redux-saga";

// Reducers
import MainReducer from "./main/MainReducer";
import { reducer as oidcReducer } from "redux-oidc";

// Subscribers
import {
  validateMainReducer,
  validateAnother,
} from "./StoreSubscribers";

// Sagas

import IAppState from "./IAppState";

export default function configureStore(
  history: History,
  initialState: IAppState
): Store<IAppState> {
  // ---REDUCERS---
  const rootReducer = combineReducers<IAppState>({
    router: connectRouter(history),
    oidc: oidcReducer,
    main: MainReducer,
  });

  // ---MIDDLEWARES---
  // Saga
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [routerMiddleware(history), sagaMiddleware];

  // ---ENHANCERS---
  const composeEnhancers = composeWithDevTools({});

  // ---CREATE STORE---
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(compose(applyMiddleware(...middleware)))
  );
  
  // ---SUBSCRIBE---
  let subscribing: boolean = false;
  store.subscribe(() => {
    if (!subscribing) {
      subscribing = true;
      [validateMainReducer, validateAnother].forEach((value, index) => {
        value(store.getState(), store.dispatch);
      });
      subscribing = false;
    }
  });

  // ---RUN---
  //sagaMiddleware.run();

  return store;
}
