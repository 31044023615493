import { createUserManager } from "redux-oidc";
import Oidc, { UserManagerSettings, User } from "oidc-client";

const userManagerConfig: UserManagerSettings = {
    client_id: process.env["REACT_APP_CLIENT_ID"],
    authority: process.env["REACT_APP_AUTHORITY"],
    response_type: "code",
    scope: "openid profile roles email address NakupniPortalAPI phone",
    redirect_uri: process.env["REACT_APP_REDIRECT_URI"],
    silent_redirect_uri: process.env["REACT_APP_SILENT_REDIRECT_URI"],
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
};

Oidc.Log.logger = console;

const UserManager = createUserManager(userManagerConfig);

UserManager.events.addSilentRenewError((error: any) => {
    if (error.hasOwnProperty("error") && error["error"] === "login_required") {
        UserManager.signinRedirect();
    }
})

export default UserManager;

//NakupniPortalAPI_Triton_ReactClient => triton
//NakupniPortalAPI_ReactClient => localhost IS


//NakupniPortalAPI_ReactClient - pro alpiq dev
//https://nakupniportal.dev.alpiq-energy.cz/ UI
//https://api.dev.alpiq-energy.cz/NakupniPortal.Api/api/v1/  api
//https://identityserver.dev.alpiq-energy.cz/IdentityServer4.IdentityServer identity

//REACT_APP_FLEXI_API_URL = https://api.dev.alpiq-energy.cz/NakupniPortal.Api/api/v1/
//REACT_APP_AUTHORITY = https://identityserver.dev.alpiq-energy.cz/IdentityServer4.IdentityServer
//REACT_APP_REDIRECT_URI = https://nakupniportal.dev.alpiq-energy.cz/callback
//REACT_APP_SILENT_REDIRECT_URI = https://nakupniportal.dev.alpiq-energy.cz/silent_renew


//NakupniPortalAPI_ReactClient - pro alpiq prod
//https://nakupniportal.alpiq-energy.cz/ UI
//https://flexiapi.alpiq-energy.cz/NakupniPortal.Api/api/v1/  api
//https://identityserver.alpiq-energy.cz/IdentityServer4.IdentityServer identity

//REACT_APP_FLEXI_API_URL = https://flexiapi.alpiq-energy.cz/nakupniportal.api/api/v1/
//REACT_APP_AUTHORITY = https://identityserver.alpiq-energy.cz/identityserver4.identityserver
//REACT_APP_REDIRECT_URI = https://nakupniportal.alpiq-energy.cz/callback
//REACT_APP_SILENT_REDIRECT_URI = https://nakupniportal.alpiq-energy.cz/silent_renew


//mtesar
//https://triton/NakupniPortalApi/api/v1/
//https://triton/OeptAlpiqActualBuild-is4
//https://mtesar/

//REACT_APP_FLEXI_API_URL = https://triton/NakupniPortalApi/api/v1/
//REACT_APP_AUTHORITY = https://triton/OeptAlpiqActualBuild-is4
//REACT_APP_REDIRECT_URI = https://mtesar/callback
//REACT_APP_SILENT_REDIRECT_URI = https://mtesar/silent_renew