import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend, { BackendOptions } from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languages = ["cz", "sk"];
// Zvolený jazyk z localstorage nebo default
const selected = localStorage.getItem("i18nextLng") || "cz";

// Cesta k překladům pro daný projekt
const getLoadPath = (): string => {
  var clientName: string = process.env.REACT_APP_CLIENT || "";

  switch (clientName) {
    default:
      return "default";
  }
};

// Konfigurace pro načítání překladů
const backendOptions: BackendOptions = {
    loadPath: `/locales/{{lng}}/${getLoadPath()}/{{ns}}.json`,
};

//load translation using xhr -> see /public/locales
//learn more: https://github.com/i18next/i18next-http-backend
i18n.use(Backend).init({
  backend: backendOptions,
});

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: selected,
    fallbackLng: "cz",
    whitelist: languages,
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;