import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IRepository from "../../../data/IRepository";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { MainActionTypes } from "../../../infrastructure/store/main/mainActionTypes";
import IAppState from "../../../infrastructure/store/IAppState";
import { UserState } from "redux-oidc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faShoppingCart, faSpinner, faBolt, faBurn } from "@fortawesome/free-solid-svg-icons";
import ModalBuy from "../../modal/ModalBuy";
import { EnvelopeDTO } from "../dto/EnvelopeDTO";
import { OpenModal } from "../dto/EnvelopeDTO";
import { Years } from "../dto/EnvelopeDTO";
import { Row, Form, Button } from "react-bootstrap";

import "react-datetime/css/react-datetime.css";

interface IProps {
    refresh: number;
}

const EnvelopeTable: React.FC<IProps> = (props) => {
    const [envelopes, setEnvelopes] = useState<EnvelopeDTO | undefined>();
    const { t } = useTranslation(["modules\\dashboard"]);
    const [envOpen, setEnvOpen] = useState<string[]>();
    const [years, setYears] = useState<Years>({od : "2021" , do : "2023"});
    const [render, setRender] = useState<boolean>(false);
    const [rerender, setRerender] = useState<number>(0);    
    const [nextLevel, setNextLevel] = useState<boolean[]>();
    const [rowspan, setRowspan] = useState<number[]>();
    const [isOpenModal, setIsOpenModal] = useState<OpenModal>({ isOpen: false, envelope: "", mail: "", rok: 0, komodita: "", clientname: "" });
    let userState = useSelector<IAppState, UserState>((store) => store.oidc);

    const getEnvelopes = async (): Promise<void> => {
        let params = new URLSearchParams();
        let email = userState.user?.profile.email;
        let year = new Date().getFullYear();

        //parametry u�ivatele
        params.set("email", email ? email : "");
        params.set("periodYearFrom", years.od);
        params.set("periodYearTo", years.do);

        const axios = require('axios');
        let url = process.env["REACT_APP_FLEXI_API_URL"];

        let data = await axios.get(url + 'GetPlanPurchaseWithInfo', { params: params });

        setEnvelopes(data.data);
    };


    useEffect(() => {
        setEnvelopes(undefined);
        getEnvelopes();
    }, [props.refresh]); 

    useEffect(() => {
        setEnvelopes(undefined);
        getEnvelopes();
    }, [rerender]); 

    useEffect(() => {
        if (envelopes) {
            let arr: string[] = [];
            let arr2: boolean[] = [];
            let arr3: number[] = [];

            envelopes.purchaseCustomerList.map((obalky, index) => {
                obalky.purchaseEnvelopeList.map((j, i) => {
                    arr.push("hidden");
                    arr2.push(false);
                    arr3.push(1);
                });
            });

            setEnvOpen(arr);
            setNextLevel(arr2);
            setRowspan(arr3);
        }
    }, [envelopes]);


    const toggleModal = (e: any, mail: string, envelope: string, rok: number, komodita: string, clientname: string, obchodujeme: boolean, zbyva: number) => {
        if (obchodujeme && zbyva != 0) {
            setIsOpenModal({ isOpen: !isOpenModal.isOpen, mail: mail, envelope: envelope, rok: rok, komodita: komodita, clientname: clientname });

            if (!isOpenModal.isOpen == false) { // refresh test
                getEnvelopes();
            }
        }        
    }

    function yearsControl(from: string, to: string) {
        var intFrom = parseInt(from) || 0;
        var intTo = parseInt(to) || 0;

        if (intTo < intFrom) {
            to = "" + (intFrom + 1);
            intTo = intFrom + 1;
        }

        if (intFrom < 2000) {
            from = "2000";
            intFrom = 2000;
        }  

        if (intFrom > 2099) {
            from = "2099";
        } 

        if (intTo > 2099) {
            to = "2099";
        }        

        setYears({ od: from, do: to })
    }

    const renderTableBody = (pEnvelopes?: EnvelopeDTO) => {
        if (!pEnvelopes)
            return (
                <tr>
                    <td colSpan={27} className="table-loading"> 
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                        <p>{t("loadingEnvelopes")}</p>
                    </td>
                </tr>
                );

        if (!envOpen)
            return

        if (!nextLevel)
            return;

        if (!rowspan)
            return;

        let pointer = 0;


        pEnvelopes.purchaseCustomerList.sort(function (a, b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        return pEnvelopes.purchaseCustomerList.map((obalky, indx) => {

            obalky.purchaseEnvelopeList.sort(function (a, b) {
                var nameA = a.purchaseEnvelopeId.toUpperCase();
                var nameB = b.purchaseEnvelopeId.toUpperCase();

                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }

                return 0;
            });

            return obalky.purchaseEnvelopeList.map((jednotliveObalky, inex) => {
                let PlanCount = 0;
                let RemainCount = 0;
                let lvl1Open = envOpen;
                let rs = rowspan;
                let trCount = 0;
                let index = pointer;
                pointer++;
                let nextLevelOpen = nextLevel;
                let BoughtTotalCount = 0;
                let BoughtTotalM: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                let isOpenEnvelopeTranches = nextLevelOpen[index] ? "" : "hidden";


                function setOpen(x: number) {
                    if (lvl1Open[x] == "") {
                        nextLevelOpen[x] = false;
                        lvl1Open[x] = "hidden";
                        rs[x] = 1;
                    } else {
                        lvl1Open[x] = "";
                        rs[x] = 4;
                    }

                    setNextLevel(nextLevelOpen);
                    setNextLevel(nextLevelOpen);
                    setEnvOpen(lvl1Open);
                    setRowspan(rs);
                    setRender(!render);
                }

                function setOpenLvl2(x: number) {
                    if (nextLevelOpen[x] == false) {
                        nextLevelOpen[x] = true;

                        rs[x] = 5 + trCount;
                    } else {
                        nextLevelOpen[x] = false;
                        rs[x] = 4;
                    }

                    setNextLevel(nextLevelOpen);
                    setRender(!render);
                }

                const PlanList = jednotliveObalky.planList.map((plan, y) => {
                    PlanCount += plan.amount;
                    let colspan = plan.month > 6 ? 2 : 1;
                    return (<td key={"a" + y} colSpan={colspan} className="blueBg center">{plan.amount.toFixed(3).replace('.', ',')}</td>)
                });

                const TrancheBoughtList = jednotliveObalky.trancheBoughtList.map((tBoughtList, p) => {
                    BoughtTotalCount += tBoughtList.amount;
                    trCount++;
                    return (
                        <tr key={p + "p"} className={isOpenEnvelopeTranches}>
                            <td className="trBg" colSpan={2}>{tBoughtList.trancheId}</td>
                            <td className="trBg center" colSpan={2}>{new Date(tBoughtList.purchasingDate).toLocaleDateString()}</td>
                            <td className="trBg center" colSpan={2}>{tBoughtList.price.toFixed(2).replace('.', ',')} {tBoughtList.currency}</td>
                            <td className="trBg center">{tBoughtList.amount}</td>

                            {tBoughtList.boughtList.map((tBoughtListList, x) => {
                                BoughtTotalM[tBoughtListList.month - 1] += tBoughtListList.amount;
                                return (<td key={x + "B"} className="trBg center">{tBoughtListList.amount.toFixed(3).replace('.', ',')}</td>)
                            })}
                        </tr>
                    )
                });

                const BoughtTotalList = (
                    <React.Fragment>
                        <td className="blueBg center">{BoughtTotalM[0].toFixed(3).replace('.', ',')}</td>
                        <td className="blueBg center">{BoughtTotalM[1].toFixed(3).replace('.', ',')}</td>
                        <td className="blueBg center">{BoughtTotalM[2].toFixed(3).replace('.', ',')}</td>
                        <td className="blueBg center">{BoughtTotalM[3].toFixed(3).replace('.', ',')}</td>
                        <td className="blueBg center">{BoughtTotalM[4].toFixed(3).replace('.', ',')}</td>
                        <td className="blueBg center">{BoughtTotalM[5].toFixed(3).replace('.', ',')}</td>
                        <td colSpan={2} className="blueBg center">{BoughtTotalM[6].toFixed(3).replace('.', ',')}</td>
                        <td colSpan={2} className="blueBg center">{BoughtTotalM[7].toFixed(3).replace('.', ',')}</td>
                        <td colSpan={2} className="blueBg center">{BoughtTotalM[8].toFixed(3).replace('.', ',')}</td>
                        <td colSpan={2} className="blueBg center">{BoughtTotalM[9].toFixed(3).replace('.', ',')}</td>
                        <td colSpan={2} className="blueBg center">{BoughtTotalM[10].toFixed(3).replace('.', ',')}</td>
                        <td colSpan={2} className="blueBg center">{BoughtTotalM[11].toFixed(3).replace('.', ',')}</td>
                    </React.Fragment>
                );

                const RemainList = jednotliveObalky.purchaseRemainList.map((remain, indexx) => {
                    RemainCount += remain.amount;
                    let colspan = remain.month > 6 ? 2 : 1;
                    return (<td key={indexx + "x"} colSpan={colspan} className="blueBg center">{remain.amount.toFixed(3).replace('.', ',')}</td>)
                });

                return (
                    <React.Fragment>
                        <tr key={index} className={"MainTrs" + (index % 2 == 1 ? " podbarveno" : "")} >
                            <td className="center">
                                {(jednotliveObalky.isOpen) ? <button title={pEnvelopes.statusDne.obchodniDen ? (RemainCount == 0 ? t("nenakoupitMnozstvi") : t("nakoupit")) : t("nenakoupitDen")} className={pEnvelopes.statusDne.obchodniDen ? (RemainCount == 0 ? "shopThis disabled" : "shopThis") : "shopThis disabled"} onClick={(e) => toggleModal(e, userState.user?.profile.email ? userState.user?.profile.email : "", jednotliveObalky.purchaseEnvelopeId, jednotliveObalky.purchaseEnvYear, jednotliveObalky.komodita, obalky.name, pEnvelopes.statusDne.obchodniDen, RemainCount)}>
                                    <FontAwesomeIcon icon={faShoppingCart} />
                                </button> : <button title={t("zavrenaobalka")} className="shopThis disabled">
                                        <FontAwesomeIcon icon={faShoppingCart} />
                                    </button>}

                                {(jednotliveObalky.komodita == "EE") ? <FontAwesomeIcon icon={faBolt} /> : <FontAwesomeIcon icon={faBurn} />} {obalky.compRegNumber}
                                
                            </td>
                            <td colSpan={7}><span className="opener" onClick={() => setOpen(index)}><FontAwesomeIcon icon={lvl1Open[index] == "" ? faMinus : faPlus} /> {obalky.name}   </span></td>
                            <td className="center">{jednotliveObalky.purchaseEnvYear}</td>
                            <td colSpan={6} className="center">{jednotliveObalky.purchaseEnvelopeId}</td>
                            <td colSpan={6} className="center">{new Date(jednotliveObalky.dateFrom).toLocaleDateString()} - {new Date(jednotliveObalky.dateTo).toLocaleDateString()}</td>
                        </tr>
                        <tr className={envOpen[index]}>
                            <th rowSpan={rowspan[index]}></th>
                            <th className="blueBg left">MWh</th>
                            <th className="blueBg">R</th>
                            <th className="blueBg">M01</th>
                            <th className="blueBg">M02</th>
                            <th className="blueBg">M03</th>
                            <th className="blueBg">M04</th>
                            <th className="blueBg">M05</th>
                            <th className="blueBg">M06</th>
                            <th colSpan={2} className="blueBg">M07</th>
                            <th colSpan={2} className="blueBg">M08</th>
                            <th colSpan={2} className="blueBg">M09</th>
                            <th colSpan={2} className="blueBg">M10</th>
                            <th colSpan={2} className="blueBg">M11</th>
                            <th colSpan={2} className="blueBg">M12</th>
                        </tr>

                        <tr className={envOpen[index]}>
                            <th className="blueBg left">{t("plan")}</th>
                            <td className="blueBg center">{PlanCount.toFixed(3).replace('.', ',')}</td>
                            {PlanList}
                        </tr>

                        <tr className={envOpen[index]}>
                            <th className="blueBg left"><span className="opener" onClick={() => setOpenLvl2(index)}><FontAwesomeIcon icon={nextLevelOpen[index] ? faMinus : faPlus} /> {t("bought")}</span></th>
                            <td className="blueBg center">{BoughtTotalCount.toFixed(3).replace('.', ',')}</td>
                            {BoughtTotalList}
                        </tr>

                        <tr className={isOpenEnvelopeTranches}>
                            <th rowSpan={trCount + 1}></th>
                            <th colSpan={2} className="trBg">{t("transe")}</th>
                            <th colSpan={2} className="trBg">Datum</th>
                            <th colSpan={2} className="trBg">Cena</th>
                            <th className="trBg">R</th>
                            <th className="trBg">M01</th>
                            <th className="trBg">M02</th>
                            <th className="trBg">M03</th>
                            <th className="trBg">M04</th>
                            <th className="trBg">M05</th>
                            <th className="trBg">M06</th>
                            <th className="trBg">M07</th>
                            <th className="trBg">M08</th>
                            <th className="trBg">M09</th>
                            <th className="trBg">M10</th>
                            <th className="trBg">M11</th>
                            <th className="trBg">M12</th>
                        </tr>

                        {TrancheBoughtList}

                        <tr className={envOpen[index]}>
                            <th className="blueBg left">{t("remain")}</th>
                            <td className="blueBg center">{RemainCount.toFixed(3).replace('.', ',')}</td>
                            {RemainList}
                        </tr>
                    </React.Fragment>
                );
            });
        });
    };

    return (
        <React.Fragment>
            <div className="selecteris diff">
                <div className="year-picker">
                    {t("od")} <Form.Control onInput={(e: any) => yearsControl(e.target.value, years.do)} type="number" min={2000} max={2099} step={1} value={years.od} />
                </div>

                <div className="year-picker">
                    {t("do")} <Form.Control onInput={(e: any) => yearsControl(years.od, e.target.value)} type="number" min={2000} max={2099} step={1} value={years.do} />
                </div>

                <Button variant="success" className="smolBtn" onClick={() => setRerender(rerender + 1)}>
                    {t("zobrazit")}
                </Button>
            </div>

            <Table bordered size="sm" className="tableMain">
                <thead>
                    <tr>
                        <th style={{ width: '100px' }}>{t("ic")}</th>
                        <th colSpan={7}>{t("name")}</th>
                        <th>{t("year")}</th>
                        <th colSpan={6}>{t("codeEnvelope")}</th>
                        <th colSpan={6}>{t("shopFromTo")}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableBody(envelopes)}
                </tbody>
            </Table>

            <ModalBuy
                isOpen={isOpenModal?.isOpen}
                envelope={isOpenModal?.envelope}
                mail={isOpenModal?.mail}
                rok={isOpenModal?.rok}
                komodita={isOpenModal?.komodita}
                clientname={isOpenModal?.clientname}
                onHide={toggleModal}
            />
        </React.Fragment>
    );
};

export default EnvelopeTable;
