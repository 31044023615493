import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Col from 'react-bootstrap/Col';
import IRepository from "../../../data/IRepository";
import { EnvelopeDTO } from "../../../features/envelope/dto/EnvelopeDTO";
import EnvelopeTable from "../../../features/envelope/components/EnvelopeTable";
import DataQuery from "../../../data/DataQuery";
import { useDispatch, useSelector } from "react-redux";
import { MainActionTypes } from "../../../infrastructure/store/main/mainActionTypes";
import TableStateModel from "../models/TableStateModel";
import IAppState from "../../../infrastructure/store/IAppState";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";


interface IProps { }

const Dashboard: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["modules\\dashboard"]);
    const [fresh, setFresh] = useState(0);

    function refresh() {
        let num = fresh+1;
        setFresh(num);
    }

    return (
        <React.Fragment>
            <Col lg="12" className="mt-3">
                <Row className="mt-2">
                    <div className="card pageCard">
                        <div className="card-header pageCardHeader bg-success">
                            <div className="pageCardHeaderItem">
                                <span className="mr-2 pageCardIcon">
                                    <FontAwesomeIcon icon="envelope" />
                                </span>
                                <h2 className="pageCardTitle">
                                    {t("ModuleName")}
                                </h2>                                
                            </div>
                            <span className="refreshIcon" onClick={refresh}>
                                <FontAwesomeIcon icon={faSync} />
                            </span>
                        </div>
                        <div className="card-body pageCardBody">
                            <EnvelopeTable refresh={fresh} />
                        </div>
                    </div>
                </Row>
            </Col>

            <footer>
                
                    {t("footer")}
                
            </footer>
        </React.Fragment>
    );
};

export default Dashboard;


