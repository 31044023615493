import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "redux-oidc";
import { Link } from "react-router-dom";
// style
import styles from "./profiledialog.module.scss";
// store
import IAppState from "infrastructure/store/IAppState";
// components
import Avatar from "features/shared/components/avatar/Avatar";

interface IProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileDialog: React.FC<IProps> = (props) => {
    // store
    const dispatch = useDispatch();
    let userState = useSelector<IAppState, UserState>((store) => store.oidc);

    // props/vars
    const { show, setShow } = props;
    const user = userState.user;

    // handlers
    const closeDialogHandler = () => {
        setShow(false);
    };

    // render
    return (
        <Modal
            show={show}
            onHide={closeDialogHandler}
            dialogClassName={styles.profileDialog}
            className={`modalUserMenu ${styles.profileModal} `}
        >
            <div className={`modal-content ${styles.profileContent}`}>
                <div className={`modalUserMenuHeader ${styles.profileHeader} `}>
                    <button
                        type="button"
                        className={`close ${styles.profileCloseBtn}`}
                        onClick={closeDialogHandler}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="row no-gutters align-items-center">
                        <div className="col-auto pr-3">
                            <Avatar size={60} icon="user" />
                        </div>
                        <div className="col">
                            <h6 className="mb-0">{user?.profile.name}</h6>
                            <small className={styles.profileHeaderInfo}>
                                {user?.profile.email}
                            </small>
                        </div>
                    </div>
                </div>
                <div className={`modal-body ${styles.profileBody}`}>
                    <div>
                        <a href={process.env["REACT_APP_AUTHORITY"] +"/Manage/ChangePassword"} target="_blank" className={styles.profileActionItem}>
                            <span className={styles.profileActionIcon}>
                                <FontAwesomeIcon icon={faKey} />
                            </span>
                            <span>Změna hesla</span>
                        </a>
                        <Link to="/logout" className={styles.profileActionItem}>
                            <span className={styles.profileActionIcon}>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                            </span>
                            <span>Odhlásit se</span>
                        </Link>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ProfileDialog;
